import { createContext, useState } from "react";


const DayContext = createContext(false, null, () => {});



 

//  ready, value, send
// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.

const DayProvider = ({ children }) => {
    const [today,setToday] = useState(new Date().toISOString().slice(0, 10));
    const [selectedDay,setselectedDay] = useState(new Date().toISOString().slice(0, 10));





    const ret = [today, selectedDay, setselectedDay];

 

    return <DayContext.Provider value={ret}>{children}</DayContext.Provider>;

};


export { DayContext, DayProvider };