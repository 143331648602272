import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MQTTProvider } from './hooks/mqttContext';
import { DayProvider } from './hooks/dayHook';
import { UserProvider } from './hooks/userHook';
import { LayoutProvider } from './hooks/LayoutHook';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DayProvider>
    <UserProvider>
    <MQTTProvider>
      <LayoutProvider>
    <App />
    </LayoutProvider>
    </MQTTProvider>
    </UserProvider>
    </DayProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
