import { createContext, useState, useEffect } from "react";


const LayoutContext = createContext(false, null, () => {});



 

//  ready, value, send
// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.

const LayoutProvider = ({ children }) => {

    const layout_test = { plot_bgcolor : "rgba(0, 0, 0, 0)", paper_bgcolor: "rgba(0, 0, 0, 0)",margin: {autoexpand:1
        ,l: 0, t:0, },autosize:1, legend: {side:"top"}};

    const [layout,setLayout] = useState(layout_test);






    const ret = [layout, setLayout];

 

    return <LayoutContext.Provider value={ret}>{children}</LayoutContext.Provider>;

};


export { LayoutContext, LayoutProvider };