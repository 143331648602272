
import './App.css';
import axios from 'axios';


import React, {useEffect, useState, useContext} from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import Qoute from './parts/Qoute';

import { mdiHumanHandsdown,mdiGlassMugVariant,mdiCoffee,mdiRobotAngry,mdiSilverwareForkKnife,mdiPhone } from '@mdi/js';
import { MQTTContext } from './hooks/mqttContext';
import Main from './parts/Main';
import Flasher from './parts/Flasher';
import { DayContext } from './hooks/dayHook';


import { UserContext } from './hooks/userHook';
 import { HashRouter, Route, Routes } from 'react-router-dom';
import Diagrams from './parts/Diagrams';
import api from './hooks/api';
import Admin from './parts/Admin';
import Header from './parts/Header';




//import { MqttContext } from 'mqtt-react-hooks';


function App() {

  const [isReady, val, setVal] = useContext(MQTTContext);
  const [today, selectedDay, setselectedDay] = useContext(DayContext);
  const [user,setUser,userList] = useContext(UserContext);
  const [dayObjects, setDayObjects] = useState();
  const [symbolsIcons, setSymbolsIcon] =  useState();

 // console.log(userList)
  const [startDate, setStartDate] = useState(new Date());
  const [avaivableDates, setAvaivableDates] = useState([]);

  function notifyMe() {
    Notification.requestPermission();
  
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }
  
  //console.log("DAAAATE   ",new Date().toISOString().slice(0, 10))
   const hasData = (date) => {
    var checker =0 ;
    if(dayObjects){
    if(dayObjects[date]!=null){
      checker =true;
    }
  }
   //console.log( hasInformation.includes(date.toISOString().slice(0, 10)));
   //console.log(date.toISOString().slice(0, 10));
   // const day = getDay(date);
    return checker;
  };
  const notify = (text) => toast(text);

//console.log(userId);
  var hasInformation = [];
  var hasInformationObject = {};

  var hasSymbols = {};
  useState(() => {   
    api.get('/api/actions')
    .then(function (response) {
      // handle success
   //   console.log(response);
   
      var symboles = response.data.data;

      setAvaivableDates(response.data.data);  
      for(const symbol in symboles){
        //console.log(symboles[symbol].symbol);
        hasSymbols[symboles[symbol].id] =  { "symbol" :  symboles[symbol].symbol, "name" :symboles[symbol].name};
       // hasInformation.push(dates[date].name);
       // hasInformationObject[dates[date].name] = dates[date].name;
      }
      //console.log("hasSymbols: ",hasSymbols);
      setSymbolsIcon(hasSymbols);
      //console.log(hasInformationObject);

      //console.log(dates); 
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  });

  useState(() => {   
    api.get('/api/days')
    .then(function (response) {
      // handle success
   //   console.log(response);
   
      var dates = response.data.data;
      setAvaivableDates(response.data.data);  
      for(const date in dates){
      //  console.log(dates[date].name);
        hasInformation.push(dates[date].name);
        hasInformationObject[dates[date].name] = dates[date].name;
      }
      //console.log(hasInformation);
      setDayObjects(hasInformationObject);
      //console.log(hasInformationObject);
     // console.log("Date: ",today);
      var dateArrayLast = dates[dates.length-1].name;
      if(today !== dateArrayLast){
        today = dateArrayLast[dates.length-1];
      } 
      //console.log(dates); 
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  
  
   });




   
  useEffect(() => {
    if(val){
     // console.log(val.action)
    let emojy = symbolsIcons[val.action].symbol;
   //   console.log("SYMBOLS: ",symbolsIcons[val.action]);

     var noti =  emojy ;
     var destination;
     if(val.destination!=='#'){
      destination = "Dich";
     } else {
      destination = "Alle";
     }
   /*  const notification = new Notification(noti + " " + destination + " von: " + userList[val.id]); */
    // …
  
    notify(<div><h3> {emojy} an {val.destination!=='#' ? "Dich": 'Alle'}</h3>  <h4>{userList[val.id].name} </h4>   </div>);
  }
  setVal(null);
  //setselectedDay(null);
  //setselectedDay(selectedDay);
    // action on update of movies
}, [val]);




  notify(val);
  
  // console.log("avaivableDates: ", avaivableDates)
  return (

  //  <MqttContext.Provider>
    <div className="App">
       <HashRouter>
<Header></Header>

      <div id="content">  
         <ToastContainer 
    newestOnTop={true}
    pauseOnFocusLoss
    theme="dark"
    autoClose={5000}
    />

      <Qoute></Qoute>

        <Routes>
                    <Route exact path="/" element={<Main />} />
                    <Route exact path="/diagrams" element={<Diagrams />} />
                    <Route exact path="/flasher" element={<Flasher />} />
                    <Route exact path="/sankey" element={<Admin />} />

  
                    </Routes>
                   
        </div> 
        </HashRouter> 
   
    </div>
  //  </MqttContext.Provider>
  );
}

export default App;
