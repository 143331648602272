import axios from 'axios';


var api = axios.create({
   baseURL: `http://api.kaffeealarm.net:8000`
  //baseURL: `http://localhost:8000`
  
});
export default axios.create({
    baseURL: `http://api.kaffeealarm.net:8000`
  //  baseURL: `http://localhost:8000`
  });


function getActionsByUser(selectedDay){
    var actionsUser;
    api.get('day/'+selectedDay+'/stats/actions/users')
    .then(res => {
      console.log(res);
      console.log(res.data);
      actionsUser = res.data;
    })
    return actionsUser;
}


export { getActionsByUser};