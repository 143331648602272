import React, { useState, useContext}from 'react';
import { LayoutProvider } from '../hooks/LayoutHook';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




import { DayContext } from '../hooks/dayHook';
import Sankey from './UsersSankey';


export default function  Admin
(props) {
    
    const [today, selectedDay, setselectedDay] = useContext(DayContext);
    const [dayObjects, setDayObjects] = useState();


    const [startDate, setStartDate] = useState(new Date());
    const [avaivableDates, setAvaivableDates] = useState([]);
  
    //console.log("DAAAATE   ",new Date().toISOString().slice(0, 10))
     const hasData = (date) => {
      var checker =0 ;
      if(dayObjects){
      if(dayObjects[date]!=null){
        checker =true;
      }
    }
     //console.log( hasInformation.includes(date.toISOString().slice(0, 10)));
     //console.log(date.toISOString().slice(0, 10));
     // const day = getDay(date);
      return checker;
    };



   // console.log(layout);

  


  return  ( 
   

<div className='dataRow'>
<div className='diagramItem'>
<DatePicker
      selected={startDate}
      
      onChange={(date) => {

    var today = date.toISOString().slice(0, 10);

    setselectedDay(date.toISOString().slice(0, 10));      
    //  console.log("hasInformation: includes  ",hasInformation.includes(date.toISOString().slice(0, 10)));
        if(today!==startDate ){setStartDate(date);
          setselectedDay(date.toISOString().slice(0, 10));
    //  console.log("Date: ",date.toISOString().slice(0, 10))
      }
    }}
      startDate={selectedDay}
      inline
     // filterDate={hasData}
    /> 
</div>
<LayoutProvider>

{/*      <UsersPie day={selectedDay}/>
     <Actions day={selectedDay}/>
     <UsersActions day={selectedDay}/> */}
     <Sankey day={selectedDay} />
     </LayoutProvider>

   
     </div>

 
  );

  }


