import { createContext, useState } from "react";
import axios from 'axios';
import api from "./api";

const UserContext = createContext(false, null, () => {});


//  ready, value, send
// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.

const UserProvider = ({ children }) => {
    const [userList, setUserList] = useState();
    const [user,setUser] = useState();
 

    useState(() => { 
    api.get('/api/users/')
    .then(function (response) {
      // handle success
      var userKeyList = {};
      //console.log("userHook: ",response.data.data)
      for(const u in response.data.data){
        userKeyList[response.data.data[u].id] = {"name": response.data.data[u].name,"color": response.data.data[u].color,"lastSeen": response.data.data[u].lastSeen}; // 
      }
      //console.log(userKeyList);
     setUserList(userKeyList);
        
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed

    }); 
  

});



    const ret = [user,setUser, userList];

 

    return <UserContext.Provider value={ret}>{children}</UserContext.Provider>;

};


export { UserContext, UserProvider };