import { createContext, useState, useContext, useEffect } from "react";
import Paho from 'paho-mqtt';
import { UserContext } from '../hooks/userHook';
const MQTTContext = createContext(false, null, () => {});



 

//  ready, value, send
// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.

const MQTTProvider = ({ children }) => {

    const [isReady, setIsReady] = useState(false);

    const [val, setVal] = useState(null);
    const [outgoingMessage, setOutgoingMessage] = useState(null);
    const [mqttClient, setMqttClient] = useState(null);
    const [user,setUser] = useContext(UserContext);

    const [userTopic,setUserTopic] = useState();

    useEffect(() => {
    // console.log("user confirmed")
     if(user){
      mqttClient.subscribe("coffee/"+user+"/in");
      setUserTopic("coffee/"+user+"/in");
    //  console.log("coffee/"+user+"/in");
    var messageAvaivable = {"id" : user};
    mqttClient.send("coffee/avaivable", JSON.stringify(messageAvaivable));
    }
    }, [user]);
   
    useEffect(() => {
      if(!mqttClient){
      var client    = new Paho.Client("65.108.55.130", Number("9001"), "clientId"+Math.random());
   
      client.onConnectionLost = onConnectionLost;
      client.onMessageArrived = onMessageArrived;
          
    
      // connect the client

      client.connect({onSuccess:onConnect,  userName:"tw", password: "onsite"});
      //client.startTrace();
      
      // called when the client connects
      function onConnect() {
        setIsReady(true);
        // Once a connection has been made, make a subscription and send a message.
       // console.log("onConnect");

   
        setMqttClient(client);
        client.subscribe("coffee/messages");
        console.log(client.isConnected)
      }
      
      // called when the client loses its connection
      function onConnectionLost(responseObject) {
        //console.log(client.getTraceLog());
        if (responseObject.errorCode !== 0) {
          console.log("onConnectionLost:"+responseObject.errorMessage);
          
        }
       
        //client.connect({onSuccess:onConnect});
      }
      
      // called when a message arrives
      function onMessageArrived(message) {
      // console.log(message.topic);
      // console.log(userTopic)
      // console.log(message.topic===userTopic );// console.log("Topic:\t"+message.topic  + "\t Message:\t" + message.payloadString );
     

       
          setVal(JSON.parse(message.payloadString));
         // console.log("user: "+JSON.parse(message.payloadString).id + " action: "+JSON.parse(message.payloadString).action+ " destination: "+ JSON.parse(message.payloadString).destination );
         // alert("user:\t\t\t"+JSON.parse(message.payloadString).id + "\naction:\t\t\t"+JSON.parse(message.payloadString).action+ "\ndestination:\t"+ JSON.parse(message.payloadString).destination );
        

        
        //console.log("onMessageArrived:"+message.payloadString);
   
      }


    }}, []);


    if(outgoingMessage){

      mqttClient.send("coffee/in" , JSON.stringify(outgoingMessage));
      setOutgoingMessage(null);
    }


    const ret = [isReady, val, setVal, setOutgoingMessage];

 

    return <MQTTContext.Provider value={ret}>{children}</MQTTContext.Provider>;

};


export { MQTTContext, MQTTProvider };