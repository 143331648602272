import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';



import Icon from '@mdi/react';
import { mdiHumanHandsdown,mdiGlassMugVariant,mdiCoffee,mdiRobotAngry,mdiSilverwareForkKnife,mdiPhone } from '@mdi/js';

import { UserContext } from '../hooks/userHook';
import { MQTTContext } from '../hooks/mqttContext';
import api from '../hooks/api';


export default function Buttons(props) {
  const [isReady, val, setVal,setOutgoingMessage] = useContext(MQTTContext);
  const [user,setUser] = useContext(UserContext);
  const [userList, setUserList] = useState();
  const [destination, setDestination] = useState("#");
  const now = new Date().toISOString().substring(0, 10);
  
  useState(() => {   if(user){
    //console.log("userID: ",props.user);
    

   api.get('/api/users/')
  .then(function (response) {
    // handle success
   // console.log(response.data.data)
    setUserList(response.data.data);
   // console.log(user.lastSeen.substring(0, 10));
//console.log( Date.now().toISOString().substring(0, 10));
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  }); 

  } 


});




  function sendSignal(e){
   // console.log(e.currentTarget);
   if(user){
 //   console.log(user);



  //  console.log(e.currentTarget.value);
  //  console.log("userID: ",user);
  //  console.log("Destination: ",destination);
    var data = {"id":user, "action":e.currentTarget.value ,"destination":destination}
    setOutgoingMessage(data);
  }

  //"coffee/in" -m '{"id":"84:0D:8E:8F:47:94","action":1,"destination":"#"}'

  }


  function setDestinationFunc(e){
    // console.log(e.currentTarget);
    if(user){
   //  console.log("newDestination: ",e.currentTarget.value);
    // console.log("userID: ",user);
    setDestination(e.currentTarget.value);
   }
   }

  return <div className='buttons'>
    <h2>Buttons</h2>
    <div className='buttonLists'>
    <div className='buttonSignals'>
    <button className='buttonField' id="coffee" onClick={sendSignal} value="1" ><Icon path={mdiCoffee} size={2} /></button>
    <button className='buttonField'id="lunch" onClick={sendSignal} value="2"><Icon path={mdiSilverwareForkKnife} size={2} /></button>
    <button className='buttonField'id="call" onClick={sendSignal} value="3">
<Icon path={mdiPhone} size={2} /></button>
    <button className='buttonField'id="annoy" onClick={sendSignal} value="4"><Icon path={mdiRobotAngry} size={2} /></button>
    <button className='buttonField'id="hug" onClick={sendSignal} value="99"><Icon path={ mdiHumanHandsdown} size={2} /></button>
    <button className='buttonField'id="beer" onClick={sendSignal} value="98">
<Icon path={mdiGlassMugVariant} size={2} /></button>
</div>

<div className='buttonSignals'>
 
 {userList ? userList.map((user) => <button key={user.id}  className='buttonField'id={user.id} onClick={setDestinationFunc} value={user.id} >{user.name} {user.lastSeen && user.lastSeen.substring(0, 10) ==  now ? "🟢" : '⚪'} </button>): <></>} {/* {user.lastSeen && user.lastSeen.substring(0, 10) ===  Date.now().toISOString().substring(0, 10) ? 1 :  2} */}

  </div>

  </div>
    
     </div>;
  }


