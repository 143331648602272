import React, { useEffect,useState, useContext } from 'react';
import axios from 'axios';
import { DayContext } from '../hooks/dayHook';
import { UserContext } from '../hooks/userHook';
import Plot from 'react-plotly.js';
import { LayoutContext } from '../hooks/LayoutHook';
import getActionsByUser from '../hooks/api'
import api from '../hooks/api';

export default function Sankey(props) {
  const [layout,setLayout]= useContext(LayoutContext);
  const [plotData, setPlotData] = useState();
  const [user,setUser, userList] = useContext(UserContext);
  const [today, selectedDay, setselectedDay] = useContext(DayContext);

  //console.log("UsersPie: ",props.day)
  
  useEffect(() => {    // Update the document title using the browser API   
   if(selectedDay){
    updateDiagram();

   }
   function updateDiagram() {
  //console.log(props.day);
 
  //getActionsByUser().then(function (response) {console.log("getActionsByUser(): ",response)})
  api.get('/api/day/sankey/'+selectedDay)
  .then(function (response) {
    //console.log(userList)
    //console.log(response.data.data)
    var sankeyData= response.data.data;
    var userListArray = sankeyData[sankeyData.length-1]


    var user=[];
    var destination=[];
    var amount=[];
    var userName=[];
    var userColor = [];


    userListArray.forEach(element => {
      userName.push(userList[element].name)
      userColor.push(userList[element].color.toString(16))
    
    });

    for (const property in sankeyData) {
      if(property !== sankeyData.length-1){

      user.push(sankeyData[property].user)
      
      destination.push(sankeyData[property].destination)
      amount.push(sankeyData[property]["COUNT(destination)"])

    }
    }

    var data = {

      type: "sankey",
    
      orientation: "h",
      arrowlen : 1,
      node: {
    
        pad: 15,
    
        thickness: 30,
    
        line: {
    
          color: "black",
    
          width: 0.5
    
        },
    
       label: userName,//  ["A1", "A2", "B1", "B2", "C1", "C2"], //label,
    
       color:userColor, // ["#fff", "#f00", "#123"], //userColor,//["blue", "blue", "blue", "blue", "blue", "blue"]
       
          },
    
    
      link: {
        
       
        source: user, //[1,2,3],
    
        target: destination, // [0,4,0],
    
        value: amount , // [15,12,15]
    
      }
    
    }
    
    console.log(data)  
  
    setPlotData([data]);
    // handle success
    //console.log(response.data);
   /* var plotData = response.data.data;
   var xArray1 = plotData[1]['x'];
   var yArray1 = plotData[1]['y'];
   var trace1 = {
   x: xArray1,
   y: yArray1,
   name:"Kaffee",
   type: "bar"
     };


     var xArray2 = plotData[2]['x'];
   var yArray2 = plotData[2]['y'];
   var trace2 = {
   x: xArray2,
   y: yArray2,
   name:"Food",
   type: "bar"
     };


     var xArray3 = plotData[3]['x'];
   var yArray3 = plotData[3]['y'];
   var trace3 = {
   x: xArray3,
   y: yArray3,
   name:"Call",
   type: "bar"
     };


     var xArray4 = plotData[4]['x'];
   var yArray4 = plotData[4]['y'];
   var trace4 = {
   x: xArray4,
   y: yArray4,
   name:"Nerv",
   type: "bar"
     };

     var xArray5 = plotData[98]['x'];
   var yArray5 = plotData[98]['y'];
   var trace5 = {
   x: xArray5
   ,
   y: yArray5,
   name:"Hug",
   type: "bar"
     };

     var xArray6 = plotData[99]['x'];
   var yArray6 = plotData[99]['y'];
   var trace6 = {
   x: xArray6,
   y: yArray6,
   name:"Beer",
   type: "bar"
     };


     var data = [trace1,trace2,trace3,trace4,trace5,trace6];



  setPlotData(data);


 */

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
}


},[selectedDay]);
  

  return <div className='diagramItem'><h3 className='h3_diagram'>Users-Actions:</h3>  <Plot   className='plotItem' data={plotData}   layout={layout} /></div>;
  }