import React, { useEffect,useState, useContext } from 'react';
import axios from 'axios';
import { DayContext } from '../hooks/dayHook';
import Plot from 'react-plotly.js';
import { LayoutContext } from '../hooks/LayoutHook';
import getActionsByUser from '../hooks/api'
import api from '../hooks/api';

export default function UsersActions(props) {
  const [layout,setLayout]= useContext(LayoutContext);
  const [plotData, setPlotData] = useState();

  const [today, selectedDay, setselectedDay] = useContext(DayContext);

  //console.log("UsersPie: ",props.day)
  
  useEffect(() => {    // Update the document title using the browser API   
   if(selectedDay){
    updateDiagram();

   }
   function updateDiagram() {
  //console.log(props.day);
 
  //getActionsByUser().then(function (response) {console.log("getActionsByUser(): ",response)})
  api.get('/api/day/'+selectedDay+'/stats/actions/users')
  .then(function (response) {
    // handle success
    //console.log(response.data);
   var plotData = response.data.data;
   var xArray1 = plotData[1]['x'];
   var yArray1 = plotData[1]['y'];
   var trace1 = {
   x: xArray1,
   y: yArray1,
   name:"Kaffee",
   type: "bar"
     };


     var xArray2 = plotData[2]['x'];
   var yArray2 = plotData[2]['y'];
   var trace2 = {
   x: xArray2,
   y: yArray2,
   name:"Food",
   type: "bar"
     };


     var xArray3 = plotData[3]['x'];
   var yArray3 = plotData[3]['y'];
   var trace3 = {
   x: xArray3,
   y: yArray3,
   name:"Call",
   type: "bar"
     };


     var xArray4 = plotData[4]['x'];
   var yArray4 = plotData[4]['y'];
   var trace4 = {
   x: xArray4,
   y: yArray4,
   name:"Nerv",
   type: "bar"
     };

     var xArray5 = plotData[98]['x'];
   var yArray5 = plotData[98]['y'];
   var trace5 = {
   x: xArray5
   ,
   y: yArray5,
   name:"Hug",
   type: "bar"
     };

     var xArray6 = plotData[99]['x'];
   var yArray6 = plotData[99]['y'];
   var trace6 = {
   x: xArray6,
   y: yArray6,
   name:"Beer",
   type: "bar"
     };


     var data = [trace1,trace2,trace3,trace4,trace5,trace6];



  setPlotData(data);




  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
}


},[selectedDay]);
  

  return <div className='diagramItem'><h3 className='h3_diagram'>Users-Actions:</h3>  <Plot  data={plotData}   layout={layout} /></div>;
  }