import React, { useEffect,useContext } from 'react';
import axios from 'axios';
import { DayContext } from '../hooks/dayHook';
import Plot from 'react-plotly.js';
import {  useState } from 'react';
import { LayoutContext } from '../hooks/LayoutHook';
import api from '../hooks/api';

export default function Actions(props) {
  const [plotData, setPlotData] = useState();
  const [plotLayout, setPlotLayout] = useState();
  const [layout,setLayout]= useContext(LayoutContext);
  const [today, selectedDay, setselectedDay] = useContext(DayContext);
  
  var xArray = [];
  var yArray = [];
  //console.log("UsersPie: ",props.day)
  
  useEffect(() => {    // Update the document title using the browser API   
   if(selectedDay){
    updateDiagram();

   }
   function updateDiagram() {
  //console.log(props.day);
  api.get('/api/day/'+selectedDay+'/stats/actions')
  .then(function (response) {
    // handle success
   // console.log(response.data);
   var plotData = response.data;

   for (let i = 0; i < plotData.data.length; i++) {
    //console.log("Data  [i]:",jdata.data[i]);
    
    switch (plotData.data[i]["action"]) {
      case 1:
      xArray.push("Kaffee")

        break;
        case 2:
        xArray.push("Essen")
        break;
        case 3:
        xArray.push("Call")
        break;
        case 4:
        xArray.push("Nerv")
        break;
        case 98:
          xArray.push("Hug")
          break;
          case 99:
            xArray.push("Beer")
            break;
      default:
        break;
    }

    yArray.push(plotData.data[i]["amount"])
  } 


  var data = [{labels:xArray, values:yArray, type:"pie"}];
  setPlotData(data);

setPlotLayout(layout);



  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
}


},[selectedDay]);
  
  

  return <div className='diagramItem'><h3 className='h3_diagram'>Actions:</h3>  <Plot  data={plotData}   layout={layout} /></div>;
  }


