import React, { useState, useContext, useEffect }from 'react';
import UsersPie from './UsersPie'
import UsersActions from './UsersActions';
import Actions from './Actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '@mdi/react';
import Buttons from './Buttons';
import { MQTTContext } from '../hooks/mqttContext';
import { DayContext } from '../hooks/dayHook';
import axios from 'axios';
export default function Flasher(props) {


  const [versionList,setVersionList] = useState();
 

  useState(() => { 
  axios.get('http://api.kaffeealarm.net:8000/api/version/')
  .then(function (response) {
    // handle success
    var versionlist = [];
  //  console.log("versionlist: ",response.data.data)
    for(const v in response.data.data){
      versionlist.push( { versionID : response.data.data[v].versionID, "text" : response.data.data[v].text});
    }
 //   console.log("parsedVersionlist: ",versionlist);
    setVersionList(versionlist);
      
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed

  }); 


});



   
var version;
function onLoad(){
 var x = document.getElementById("version_select").value; 
 var espweb = document.getElementById("esp-web"); 
 espweb.manifest = './firmware/'+x;
 console.log(espweb.manifest);
 console.log(x);
 version = x;
 
 
 }
  return  ( 


 <div className="selector">
  <div>
  {versionList ?  versionList.map((data, idx) => (
        <div key={data.versionID} >
        <p >{data.versionID}</p>
        <p >{data.text}</p>
        </div>
      )): <></>}
  </div>
  <div>
 <select  onChange={onLoad}  name="version" id="version_select">
 <option id="v1.5"  value="manifest_v1_5.json">V1.5</option><option id="v1" value="manifest.json">V1</option>

<option id="v1.5.1" value="manifest_v1_5_Improv.json">V1.5 IMprov</option>

</select> 
</div>
<div>
 <esp-web-install-button id="esp-web" manifest="" install-supported="">
<button slot="activate">KaffeeAlarm installieren</button> 
</esp-web-install-button>
</div>
</div>

  );

  }


