import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { DayContext } from '../hooks/dayHook';
import { UserContext } from '../hooks/userHook';
import { LayoutContext } from '../hooks/LayoutHook';
import api from '../hooks/api';

export default function UsersPie(props) {
  const [layout,setLayout]= useContext(LayoutContext);
  const [plotData, setPlotData] = useState();
  const [today, selectedDay, setselectedDay] = useContext(DayContext);
  const [user,setUser, userList] = useContext(UserContext);
//console.log(layout);
  var xArray = [];
  var yArray = [];
  //console.log("UsersPie: ",props.day)

  useEffect(() => {    // Update the document title using the browser API   
   if(selectedDay&&userList){
    updateDiagram();
   
   } 


   function updateDiagram() {
  //console.log(props.day);
  api.get('api/day/'+selectedDay+'/stats/users')
  .then(function (response) {
    // handle success
   // console.log(response.data);

    var plotData = response.data;
          for (let i = 0; i < plotData.data.length; i++) {
      //console.log(jdata2.data[i]);
      
        xArray.push(userList[plotData.data[i]["user"]].name);


      yArray.push(plotData.data[i]["amount"])
      //console.log(yArray2)

 var localData = [{labels:xArray, values:yArray, type:"pie"}];
    setPlotData(localData);

    } 

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
}


},[selectedDay]);
  


  return <div className='diagramItem'><h3 className='h3_diagram'>Users:</h3>  <Plot className="diagram" data={plotData}   layout={layout} /></div>;
  }


