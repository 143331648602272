import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { UserContext } from '../hooks/userHook';

export default function Header() {
  const navigate = useNavigate();
    const [user,setUser,userList] = useContext(UserContext);
    const [userName, setUserName] = useState();
    function logOut(){
        localStorage.removeItem("userId");
        setUser(null);
        navigate('/');
       }
    
       function setUserMAC(){
        var userMAC = document.getElementById("userMAC").value
        //console.log("userMAC: ",userMAC);
        if(userList[userMAC]){
    setUser(userMAC);
    localStorage.setItem("userId", userMAC);
    setUserName(userList[userMAC].name)
        }
        //var regexMac = /^([0-9a-fA-F]{2}\W){6}$/i
    
    
        //console.log(pattern);
        //console.log("Match: ",regexMac.test(userMAC));
    
    
       }
    
    
    

  return <div id="menu">
      
      <div><label htmlFor="userMAC">Username:</label>

      {localStorage.getItem("userId") ? 
      <input id='userMAC' type="text"  defaultValue={localStorage.getItem("userId")}  minLength="17" maxLength="17"></input> : 

      <input id='userMAC' type="text"   minLength="17" maxLength="17"></input>}
      <input onClick={setUserMAC} type="button" value="Submit"></input>
 
        {user ?
        <div id="userID">{userName}</div> : <></>} 
{user ? <div> <input onClick={logOut} type="button" value="Logout"></input></div> :<></> }       
       </div>
       <div>
      <ul>
        <li className='menuItem'>
          <a  className='menuLink' href="#/">Home </a>
        </li>
        <li className='menuItem'>
        <a className='menuLink' href="#/diagrams">Diagrams </a>
        </li>
        <li className='menuItem'>
        <a  className='menuLink' href="#/flasher">Flasher </a>
       
        </li>
 
        <li className='menuItem'>
        <a  className='menuLink' href="#/sankey">Sankey </a>
        </li>
      </ul>

      </div>
   
   {/*  <button onClick={notifyMe}>Notify me!</button> */}
      </div>
;
}

