import React, { useState, useContext, useEffect }from 'react';
import UsersPie from './UsersPie'
import UsersActions from './UsersActions';
import Actions from './Actions';
import { LayoutContext, LayoutProvider } from '../hooks/LayoutHook';

import 'react-toastify/dist/ReactToastify.css';
import Icon from '@mdi/react';
import Buttons from './Buttons';


import { DayContext } from '../hooks/dayHook';
import { UserContext } from '../hooks/userHook';

export default function  Main(props) {
    
    const [today, selectedDay, setselectedDay] = useContext(DayContext);
    //console.log(selectedDay);
    const [selectDay, setSelectedDay] = useState(today);
    const [user,setUser, userList] = useContext(UserContext);
 



   // console.log(layout);

  


  return  ( 
    <>
 {/*    <Flasher>

    </Flasher> */}

   {user ?

  <Buttons user={user}/> : <></>
 }
<div className='dataRow'>

<LayoutProvider>

     <UsersPie day={selectedDay}/>
     <Actions day={selectedDay}/>
     <UsersActions day={selectedDay}/>
     
     </LayoutProvider>
     </div>

     </>
  );

  }


