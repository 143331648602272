import React, { useState } from 'react';
import axios from 'axios';
import api from '../hooks/api';



export default function Qoute(props) {

  const [qoute, setQoute] = useState();


  
  useState(() => { 
    //console.log("userID: ",props.user);
    

   api.get('/api/qoute')
  .then(function (response) {
    // handle success
   // console.log(response.data.data)
   setQoute(response.data.data);

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  }); 

  


},[qoute]);


function newQoute(){
  api.get('/api/qoute')
  .then(function (response) {
    // handle success
   // console.log(response.data.data)
   setQoute(response.data.data);

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  }); 

}




  return <div className='qouteContainer'>
    <div id= "qouteBox" >
    <div id= "qoute" >
     {qoute ? qoute.Qoute : <></>}
      
     {qoute && qoute.Source ? <p> {qoute.Source}<button onClick={newQoute}>⟳</button>  </p> : <p><button onClick={newQoute}>⟳</button></p>}
    
     </div>
     </div>
     </div>;
  }


